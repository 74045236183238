import { setupCkEditor } from "./SharedFunctions";

const showHideDelay = 200;

let editor = null;
let editorEkstern = null;

$(document).ready(function () {    
    smsAndMailSetup();
});

const enableMailEditor = function() {
    if (document.getElementById("SmsOgMailModel_SendMailTilElev").checked) {
        $(".sendMailRow").show(showHideDelay, function () {
            editor = setupCkEditor("SmsOgMailModel_MailTemplate_BodyHtml", editor);
        });        
    } else {
        $(".sendMailRow").hide(showHideDelay);
    }
};

const enableMailEditorEkstern = function () {
    if (document.getElementById("SmsOgMailModel_SendMailTilEkstern").checked) {
        $(".sendMailEksternRow").show(showHideDelay, function () {
            editorEkstern = setupCkEditor("SmsOgMailModel_MailTemplateEkstern_BodyHtml", editorEkstern);
        });

    } else {
        $(".sendMailEksternRow").hide(showHideDelay);
    }
};

const enableSmsEditor = function () {
    if (document.getElementById("SmsOgMailModel_SendSmsTilElev").checked) {
        $(".sendSmsRow").show(showHideDelay);
    } else {
        $(".sendSmsRow").hide(showHideDelay);
    }
};

export const smsAndMailSetup = () => {
    editor = null;
    editorEkstern = null;

    $("#SmsOgMailModel_SmsTemplateID").change(function () {

        const smsTemplate = $(this).val();

        if (smsTemplate) {
            $("#SmsOgMailModel_SmsTemplate_Text").val("Henter skabelon...");
            const url = "/Dokument/HentSmsSkabelonTekst/";

            $.ajax({
                url: url,
                data: { templateId: smsTemplate },
                dataType: 'json',
                cache: false,
                success: function (data) {
                    $("#SmsOgMailModel_SmsTemplate_Text").val(data.text);
                    $("#SmsOgMailModel_SmsTemplate_Description").val(data.name);
                }
            });

        } else {
            $("#SmsOgMailModel_SmsTemplate_Text").val("");
            $("#SmsOgMailModel_SmsTemplate_Description").val("");
        }
    });

    $("#SmsOgMailModel_MailTemplateID").change(function () {

        const mailTemplate = $(this).val();

        if (mailTemplate) {
            editor.setData("Henter skabelon....");

            const url = "/Dokument/HentMailSkabelonTekst/";

            $.ajax({
                url: url,
                data: { templateId: mailTemplate },
                dataType: 'json',
                cache: false,
                success: function (data) {
                    editor.setData(data.text);
                    $("#SmsOgMailModel_MailTemplate_Subject").val(data.title);
                    $("#SmsOgMailModel_MailTemplate_BodyHtml").val(data.text);
                }
            });

        } else {
            $("#SmsOgMailModel_MailTemplate_BodyHtml").val("").blur();
        }
    });

    $("#SmsOgMailModel_MailTemplateEksternID").change(function () {

        const mailTemplate = $(this).val();

        if (mailTemplate) {
            editorEkstern.setData("Henter skabelon...");

            const url = "/Dokument/HentMailSkabelonTekst";

            $.ajax({
                url: url,
                data: { templateId: mailTemplate },
                dataType: 'json',
                cache: false,
                success: function (data) {
                    editorEkstern.setData(data.text);
                    $("#SmsOgMailModel_MailTemplateEkstern_Subject").val(data.title);
                    $("#SmsOgMailModel_MailTemplateEkstern_BodyHtml").val(data.text);
                }
            });            

        } else {
            $("#SmsOgMailModel_MailTemplateEkstern_BodyHtml").val("").blur();
        }
    });

    $("#SmsOgMailModel_MailTilEksterne_Select2").select2({
        placeholder: "Angiv en modtager",
        minimumInputLength: 3,
        ajax: {
            url: "/Email/SoegEmailModtagereForBruger",
            dataType: "json",
            quietMillis: 100,
            data: function (params) {
                return {
                    text: params.term || "",
                    page: params.page || 1
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;
                return {
                    results: mapResults(data),
                    pagination: {
                        more: (params.page * 30) < data.Length
                    }
                };
            },
            cache: false
        },
        templateResult: function (item) {
            return item.text;
        },
        templateSelection: function (item) {
            return item.text;
        },
        id: function (item) {
            return item.text;
        },
        multiple: true,
        width: "304"
    });

    const mapResults = (data) => {

        const result = [];

        for (let i = 0; i < data.length; i++) {
            const item = {
                id: data[i],
                text: data[i]
            };

            result.push(item);
        }

        return result;
    };

    $("#SmsOgMailModel_MailTilEksterne_Select2").on("select2:select select2:close", function (e) {
  
        const value = $("#SmsOgMailModel_MailTilEksterne_Select2").val();
        $("#SmsOgMailModel_MailTilEksterne").val(value);
    });
    
    $("#SmsOgMailModel_SendMailTilElev").change(function () {
        enableMailEditor();
    });

    $("#SmsOgMailModel_SendSmsTilElev").change(function() {
        enableSmsEditor();
    });

    $("#SmsOgMailModel_SendMailTilEkstern").change(function() {
        enableMailEditorEkstern();
    });
};
