import { GlobalLoader } from "../Shared/GlobalLoader";
import { DialogController } from "../Shared/DialogController";

export class ModalForm {

    constructor(onValidation, onReinitialize) {
        this.validations = [];
        this.onValidation = onValidation;
        this.onReinitialize = onReinitialize;
    }

    addValidation(id, message) {
        this.validations.push([id, message]);
    }

    findError() {
        let error = this.findValidationError();
        if (error === undefined && this.onValidation) {
            error = this.onValidation();
        }
        return error;
    }

    findValidationError() {
        let error = this.validations.find(function (validation) {
            const id = validation[0];
            return $(id).val() === undefined || $(id).val() === '';
        });
        return error ? error[1] : undefined;
    }

    redirect(result) {
        let parameterList = "?" + this.getParameterList(result.parameters);
        $("#modal-eduadm-generic-body").load(`${result.url}${parameterList}`, this.onReinitialize);
    }

    getParameterList(parameters) {
        let parameterList = "";
        Object.keys(parameters).forEach(function (parameter) {
            if (Array.isArray(parameters[parameter])) {
                parameters[parameter].forEach(function (p) {
                    parameterList += `${parameter}=${p}&`;
                });
            } else {
                parameterList += `${parameter}=${parameters[parameter]}&`;
            }
        });
        return parameterList;
    }

    showValidationErrors(validationId, errorMessages) {
        $(validationId).show();
        $(validationId).html('<ul>');
        errorMessages.forEach(function (message) {
            $(validationId).append(`<li>${message}</li>`);
        });
        $(validationId).append('<ul>');
    }

    clearValidationInput() {
        this.validations.forEach(function (validation) {
            const id = validation[0];
            $(id).val('');
        });
    }

    handleScrollingOfModals() {
        $("#modal-eduadm-generic").css('overflow-y', 'auto');
        $("body").css('overflow-y', 'hidden');
        $("#modal-eduadm-generic").on('hidden.bs.modal', function () {
            $("body").css('overflow-y', 'inherit');
        });
    }

    initialize(formId, loaderText, validationId, confirmSubmission) {
        $(formId).submit(function(e) {
            e.preventDefault();

            if (CKEDITOR) {
                for (var instance in CKEDITOR.instances) {
                    CKEDITOR.instances[instance].updateElement();
                }
            }

            const error = this.findError();
            if (error !== undefined) {
                DialogController.showAlert(error);
                this.handleScrollingOfModals();
                return false;
            }

            if (confirmSubmission) {
                if (!confirmSubmission()) return false;
            }

            const formData = new FormData($(formId)[0]);
            const formUrl = e.target.action;
            GlobalLoader.showLoader(`${loaderText} - vent venligst`);

            $.ajax({
                url: formUrl,
                cache: false,
                xhrFields: {
                    withCredentials: true
                },
                type: "POST",
                dataType: "JSON",
                data: formData,
                success: (result) => {
                    
                    if(result.status === 202) {
                        GlobalLoader.hideLoader();
                        this.showValidationErrors(validationId, result.errorMessages);
                        
                        return;
                    }
                    
                    if (result.redirect) {
                        this.redirect(result);
                        GlobalLoader.hideLoader();
                    } else {
                        location.reload(true);
                    }
                    
                    $(validationId).hide();
                },
                error: (err) => {
                    GlobalLoader.hideLoader();
                },
                processData: false,
                contentType: false,
            });
        }.bind(this));
    }
}