export const setupCkEditor = (targetElement, editor) => {
    if (editor === null || editor === undefined) {
        CKEDITOR.config.removePlugins = "pastefromword,uploadimage,filebrowser,maximize,pastetext,image,specialchar,table,tabletools,tableselection,undo,blockquote,about,horizontalrule,,magicline,removeformat,stylescombo";
        CKEDITOR.on("instanceReady", function() {
            $(".cke_toolbar_break").hide();
        });
        editor = CKEDITOR.replace(targetElement, {
            language: 'da',
            entities: false
        });
    }

    return editor;
};